import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import AboutPage from '~/components/About/index';
import ContentFeatureHero from '~/components/Content/Page/ContentFeatureHero';

const About = ({ data }) => {
  const { page } = data;
  const { hero, mobileHero } = page;

  return (
    <Layout transparentNav={page.transparentNav} navColor={page.navColor}>
      <Metadata title={page.name} />
      <ContentFeatureHero hero={hero} mobileHero={mobileHero} />
      <AboutPage page={page} />
    </Layout>
  );
};

export default About;

export const query = graphql`
  query AboutPage($locale: String) {
    page: contentfulPage(slug: { eq: "about" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`;
