import { Box } from 'theme-ui';
// import { Link } from 'gatsby';

import AboutHero from '~/components/About/AboutHero';
import AboutValues from '~/components/About/AboutValues';
// import { useState } from 'react';

const AboutPage = ({ page }) => {
  const email = page.sections.find((section) => section.slug === 'get-in-touch');

  return (
    <Box
      color="primary"
      sx={{
        '--paraSize': ['1.4rem', null, '1.5rem'],
        '--paraHeight': ['1.8rem', null, '2.1rem'],
        borderBottom: '1px solid #5C604D20',
        padding: ['3rem 3rem 0', null, '6rem 8rem 0'],
        backgroundColor: 'white',
        alignSelf: 'center',
      }}
    >
      {page.sections.map((section) => {
        if (section.slug === 'our-story') return <AboutHero section={section} email={email} />;
        if (section.slug === 'our-values') return <AboutValues section={section} />;
        return <Box />;
      })}
    </Box>
  );
};

export default AboutPage;
