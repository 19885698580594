import { Box, Flex, Text } from 'theme-ui';

const AboutValues = ({ section }) => {
  return (
    <Box
      sx={{
        borderBottom: '1px solid #5C604D20',
        paddingBottom: ['10rem', null, '5.7rem'],
        paddingTop: ['5rem', null, 0],
      }}
    >
      <Text as="h2" variant="h5" sx={{ fontSize: '2rem', lineHeight: '2rem', mb: '7rem' }}>
        {section.title}
      </Text>
      <Flex sx={{ gap: ['6rem', null, '5.2rem'], flexWrap: ['wrap', null, 'nowrap'] }}>
        {section.media?.map((value) => {
          return (
            <Box key={value.id} sx={{ maxWidth: ['100%', null, '50em'] }}>
              <Text as="h3" variant="h3" sx={{ fontSize: ['3rem', null, '3.4rem'] }}>
                {value.title}
              </Text>
              <Box
                sx={{ p: { fontSize: 'var(--paraSize)', lineHeight: 'var(--paraHeight)' } }}
                dangerouslySetInnerHTML={{
                  __html: value.description?.childMarkdownRemark.html,
                }}
              />
            </Box>
          );
        })}
      </Flex>
    </Box>
  );
};

export default AboutValues;
