import { Text, Box, Link } from 'theme-ui';

const Email = ({ section, forwardSx = {} }) => {
  const jsonLink = section.slug === 'get-in-touch' && JSON.parse(section?.cta?.raw);

  const emailLink = jsonLink?.content?.[0]?.content?.[1]?.data.uri;
  const emailText = jsonLink?.content?.[0]?.content?.[1]?.content[0]?.value;
  return (
    <Box sx={{ mt: '7rem', pb: ['6rem', null, '11rem'], ...forwardSx }}>
      <Text as="h4" variant="h5" sx={{ margin: 0, lineHeight: '2rem', mb: '2rem' }}>
        {section.title}
      </Text>
      <Box
        sx={{
          fontSize: '1.5rem',
          lineHeight: '2.1rem',
          margin: 0,
          maxWidth: '47.7rem',
          p: { margin: 0, mb: ['1.4rem', null, '2.5rem'] },
        }}
        dangerouslySetInnerHTML={{
          __html: section.description?.childMarkdownRemark.html,
        }}
      />
      <Link
        sx={{
          margin: 0,
          fontSize: ['1.6rem', null, '1.8rem'],
          fontWeight: 'medium',
          cursor: 'pointer',
        }}
        href={emailLink}
      >
        {emailText}
      </Link>
    </Box>
  );
};

export default Email;
