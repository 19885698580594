import { Box, Flex } from 'theme-ui';
import Email from '~/components/About/AboutEmail';
import ImgLoader from '../Generic/ImgLoader';

const AboutHero = ({ section, email }) => {
  return (
    <Flex
      sx={{
        p: {
          strong: {
            fontSize: '2.4rem',
            lineHeight: ['2.7rem', null, '3rem'],
            width: ['12em', null, '100%'],
            fontWeight: 'medium',
            display: 'inline-block',
            mt: [null, null, '-7px'],
            mb: ['2rem', null, '2.4rem'],
          },
        },
        flexWrap: ['wrap', null, 'nowrap'],
        pb: ['8rem', null, '10rem'],
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: ['100%', null, '67rem'],
          height: ['23.6rem', '46rem'],
          mr: [0, null, '6.7rem'],
          mb: ['3rem', null, 0],
          flexShrink: 0,
        }}
      >
        <ImgLoader
          image={section?.media[0]?.images?.[0]}
          forwardSx={{
            width: '100%',
            height: '100%',
            borderTopLeftRadius: '2.3rem',
            borderBottomRightRadius: '7.2rem',
          }}
        />
      </Box>
      <Flex sx={{ flexDirection: 'column', alignContent: 'center' }}>
        <Box
          sx={{
            maxWidth: ['100%', null, '50em'],
            pb: ['7rem', null, 0],
            borderBottom: ['1px solid #5C604D20', null, 'none'],
            h3: {
              m: 0,
              mb: ['2rem', null, '2.4rem'],

              fontSize: ['3rem', null, '3.4rem'],
              lineHeight: ['2.7rem', null, '3rem'],
            },
            p: {
              margin: 0,
              fontWeight: 400,
              fontSize: 'var(--paraSize)',
              lineHeight: 'var(--paraHeight)',
            },
          }}
          dangerouslySetInnerHTML={{
            __html: section.description?.childMarkdownRemark.html,
          }}
        />
        {email && (
          <Email
            section={email}
            forwardSx={{ display: 'block', pb: ['7rem', null, 0], borderBottom: ['1px solid #5C604D20', null, 'none'] }}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default AboutHero;
